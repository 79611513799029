import { HeaderViewModel } from '../header-view-model/headerViewModel';

export class HeaderViewModelBuilder {
  viewModel: HeaderViewModel = {
    title: '',
    imageUri: '506418dbb019414f951a61670f3255a8.jpg',
  };

  withTitle(title: string) {
    this.viewModel.title = title;
    return this;
  }

  withImageUri(imageUri: string) {
    this.viewModel.imageUri = imageUri;
    return this;
  }

  build() {
    return this.viewModel;
  }
}
