import { DetailsSectionViewModel } from '../details-section-view-model/detailsSectionViewModel';

export class DetailsSectionViewModelBuilder {
  viewModel: DetailsSectionViewModel = {
    daysOffered: '',
    duration: '',
    price: '',
    location: '',
  };

  withDaysOffered(daysOffered: string) {
    this.viewModel.daysOffered = daysOffered;
    return this;
  }
  withDuration(duration: string) {
    this.viewModel.duration = duration;
    return this;
  }
  withPrice(price: string) {
    this.viewModel.price = price;
    return this;
  }
  withLocation(location: string) {
    this.viewModel.location = location;
    return this;
  }
  build(): DetailsSectionViewModel {
    return this.viewModel;
  }
}
