import { CatalogServiceDto } from '@wix/bookings-uou-types/dist/src';

export interface HeaderViewModel {
  title: string;
  imageUri: string;
}

export const headerViewModelFactory = (serviceDto: CatalogServiceDto) => {
  return {
    title: serviceDto.info.name,
    imageUri: serviceDto.info.images[0]?.relativeUri,
  };
};
