import { ISettingsParam, ISettingsParams } from '@wix/tpa-settings';
import {
  AlignmentOptions,
  ImagePositionOptions,
  ISection,
  SectionTypes,
  SidebarPosition,
} from './types';

export type IComponentSettings = ISettingsParams<{
  policySectionTitle: ISettingsParam<string>;
  sections: ISettingsParam<ISection[]>;
  headerVisibility: ISettingsParam<boolean>;
  headerTitleVisibility: ISettingsParam<boolean>;
  headerTitleAlignment: ISettingsParam<AlignmentOptions>;
  headerImageVisibility: ISettingsParam<boolean>;
  headerImagePosition: ISettingsParam<ImagePositionOptions>;
  headerBookButtonVisibility: ISettingsParam<boolean>;
  sidebarVisibility: ISettingsParam<boolean>;
  onlineBadgeVisibility: ISettingsParam<boolean>;
  onlineBadgeText: ISettingsParam<string>;
  serviceTitleVisibility: ISettingsParam<boolean>;
  serviceTaglineVisibility: ISettingsParam<boolean>;
  titleAndTaglineVisibility: ISettingsParam<boolean>;
  detailsDaysVisibility: ISettingsParam<boolean>;
  detailsDurationVisibility: ISettingsParam<boolean>;
  detailsPriceVisibility: ISettingsParam<boolean>;
  detailsLocationVisibility: ISettingsParam<boolean>;
  detailsBoxAlignment: ISettingsParam<AlignmentOptions | undefined>;
  detailsTextAlignment: ISettingsParam<AlignmentOptions | undefined>;
  detailsButtonAlignment: ISettingsParam<AlignmentOptions | undefined>;
  titleAndTaglineAlignment: ISettingsParam<AlignmentOptions | undefined>;
  businessEmailVisibility: ISettingsParam<boolean>;
  businessPhoneNumberVisibility: ISettingsParam<boolean>;
  businessAddressVisibility: ISettingsParam<boolean>;
  contactDetailsTitleText: ISettingsParam<string>;
  descriptionTitleText: ISettingsParam<string>;
  bookButtonText: ISettingsParam<string>;
  serviceUnavailableMessageText: ISettingsParam<string>;
  fullCourseMessageText: ISettingsParam<string>;
  unbookableCourseMessageText: ISettingsParam<string>;
  sidebarSection: ISettingsParam<SectionTypes>;
  sidebarFreezePosition: ISettingsParam<boolean>;
  sidebarPosition: ISettingsParam<SidebarPosition>;
  sidebarAlignment: ISettingsParam<AlignmentOptions>;
  sidebarWidth: ISettingsParam<number>;
  sidebarSideSpacing: ISettingsParam<number>;
  bodyAlignment: ISettingsParam<AlignmentOptions>;
}>;

export const defaultSections: ISection[] = [
  {
    type: SectionTypes.TITLE_TAGLINE,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.DESCRIPTION,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.DETAILS,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.POLICY,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.CONTACT,
    visible: true,
    bookButton: false,
  },
  {
    type: SectionTypes.SCHEDULING,
    visible: true,
    bookButton: false,
  },
];

export const componentSettings: IComponentSettings = {
  sidebarWidth: {
    key: 'sidebarWidth',
    getDefaultValue: () => 30,
  },
  sections: {
    key: 'sections',
    getDefaultValue: () => defaultSections,
  },
  headerVisibility: {
    key: 'headerVisibility',
    getDefaultValue: () => true,
  },
  headerTitleVisibility: {
    key: 'headerTitleVisibility',
    getDefaultValue: () => true,
  },
  headerTitleAlignment: {
    key: 'headerTitleAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  headerImageVisibility: {
    key: 'headerImageVisibility',
    getDefaultValue: () => true,
  },
  headerImagePosition: {
    key: 'headerImagePosition',
    getDefaultValue: () => ImagePositionOptions.MIDDLE,
  },
  headerBookButtonVisibility: {
    key: 'headerBookButtonVisibility',
    getDefaultValue: () => true,
  },
  sidebarVisibility: {
    key: 'sidebarVisibility',
    getDefaultValue: () => false,
  },
  sidebarSection: {
    key: 'sidebarSection',
    getDefaultValue: () => SectionTypes.DETAILS,
  },
  sidebarFreezePosition: {
    key: 'sidebarFreezePosition',
    getDefaultValue: () => false,
  },
  sidebarSideSpacing: {
    key: 'sidebarSideSpacing',
    getDefaultValue: () => 0,
  },
  sidebarPosition: {
    key: 'sidebarPosition',
    getDefaultValue: () => SidebarPosition.RIGHT,
  },
  sidebarAlignment: {
    key: 'sidebarAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.LEFT,
  },
  onlineBadgeVisibility: {
    key: 'onlineBadgeVisibility',
    getDefaultValue: () => true,
  },
  serviceTitleVisibility: {
    key: 'serviceTitleVisibility',
    getDefaultValue: () => true,
  },
  titleAndTaglineVisibility: {
    key: 'titleAndTaglineVisibility',
    getDefaultValue: () => true,
  },
  serviceTaglineVisibility: {
    key: 'serviceTaglineVisibility',
    getDefaultValue: () => true,
  },
  titleAndTaglineAlignment: {
    key: 'titleAndTaglineAlignment',
    getDefaultValue: () => undefined,
  },
  policySectionTitle: {
    key: 'policySectionTitle',
    getDefaultValue: () => '',
  },
  detailsDaysVisibility: {
    key: 'detailsDaysVisibility',
    getDefaultValue: () => true,
  },
  detailsDurationVisibility: {
    key: 'detailsDurationVisibility',
    getDefaultValue: () => true,
  },
  detailsPriceVisibility: {
    key: 'detailsPriceVisibility',
    getDefaultValue: () => true,
  },
  detailsLocationVisibility: {
    key: 'detailsLocationVisibility',
    getDefaultValue: () => true,
  },
  detailsBoxAlignment: {
    key: 'detailsBoxAlignment',
    getDefaultValue: () => undefined,
  },
  detailsTextAlignment: {
    key: 'detailsTextAlignment',
    getDefaultValue: () => undefined,
  },
  detailsButtonAlignment: {
    key: 'detailsButtonAlignment',
    getDefaultValue: () => undefined,
  },
  businessEmailVisibility: {
    key: 'businessEmailVisibility',
    getDefaultValue: () => true,
  },
  businessPhoneNumberVisibility: {
    key: 'businessPhoneNumberVisibility',
    getDefaultValue: () => true,
  },
  businessAddressVisibility: {
    key: 'businessAddressVisibility',
    getDefaultValue: () => true,
  },
  contactDetailsTitleText: {
    key: 'contactDetailsTitleText',
    getDefaultValue: () => '',
  },
  descriptionTitleText: {
    key: 'descriptionTitleText',
    getDefaultValue: () => '',
  },
  onlineBadgeText: {
    key: 'onlineBadgeText',
    getDefaultValue: () => '',
  },
  bookButtonText: {
    key: 'bookButtonText',
    getDefaultValue: () => '',
  },
  serviceUnavailableMessageText: {
    key: 'serviceUnavailableMessageText',
    getDefaultValue: () => '',
  },
  fullCourseMessageText: {
    key: 'fullCourseMessageText',
    getDefaultValue: () => '',
  },
  unbookableCourseMessageText: {
    key: 'unbookableCourseMessageText',
    getDefaultValue: () => '',
  },
  bodyAlignment: {
    key: 'bodyAlignment',
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.LEFT,
  },
};
